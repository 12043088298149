import { Button } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { AxiosProxy } from '../../../api/axiosProxy/axiosProxy';
import { useFetchUserPermissions } from '../../../api/user/useFetchUserPermissions';
import BasicSearch from '../../../components/BasicSearch';
import { useDeleteLabel } from '../../../hooks/labels/useDeleteLabel';
import { usePostLabel } from '../../../hooks/labels/usePostLabel';
import { usePutLabel } from '../../../hooks/labels/usePutLabel';
import { LabelInterface } from '../../../hooks/queries/useFetchLabels';
import { captureExceptionWithMessage } from '../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import '../../../scss/pages/labels.scss';
import Label from './Label';
import styles from './Labels.module.scss';
import { useOrganizationFeatureFlag } from '../../../hooks/useOrganizationFeatureFlag';

export type InterfaceManageableLabel = Omit<LabelInterface, 'dateCreated'> & {
  new?: boolean;
  isEditing?: boolean;
  dateCreated: Date;
};

function Labels() {
  const { t } = useTranslation();
  const [allLabels, setAllLabels] = useState<any[]>([]);
  const [labels, setLabels] = useState<InterfaceManageableLabel[]>([]);
  const [newLabelButtonDisabled, setNewLabelButtonDisabled] = useState(false);
  const { mutateAsync: createLabel } = usePostLabel();
  const { mutateAsync: updateLabel } = usePutLabel();
  const { mutateAsync: deleteLabel } = useDeleteLabel();
  const { hasPermissions } = useFetchUserPermissions();
  const hasWriteAccess = hasPermissions({
    requiredPermissions: ['create:label', 'update:label', 'delete:label'],
  });
  const { isEnabled: importGhLabelsFeatureFlag } =
    useOrganizationFeatureFlag('import-gh-labels');

  const fetchLabels = async () => {
    try {
      const { success, data } = await AxiosProxy.get({ url: '/labels' });
      if (success) {
        return data;
      } else {
        throw new Error(t('page.userSettings.labels.error.fetch'));
      }
    } catch (e) {
      captureExceptionWithMessage('fetchLabels', e);
    }
  };

  useEffect(() => {
    fetchLabels()
      .then((res) => {
        const labels = res.map((label: LabelInterface) => {
          const { _id, name, color, dateCreated, includedInCount, source } = label;
          return {
            _id,
            name,
            color,
            includedInCount,
            dateCreated: dateCreated ? new Date(dateCreated) : new Date(),
            isEditing: false,
            source,
          };
        });
        setLabels(labels);
        setAllLabels(labels);
        setNewLabelButtonDisabled(false);
      })
      .catch((err) => {
        captureExceptionWithMessage('fetchLabels', err);
      });
  }, []);

  const handleSearch = (searchValue: string) => {
    if (!searchValue) {
      return setLabels(allLabels);
    }
    const filteredLabels = allLabels.filter((label: any) => {
      return label?.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
    });
    setLabels(filteredLabels);
  };

  const startCreateNewLabel = () => {
    const newLabel = {
      new: true,
      isEditing: true,
      source: 'manifest',
    };
    setLabels((prevLabels: any[]) => [newLabel, ...prevLabels]);
    setNewLabelButtonDisabled(true);
  };

  const handleUpdate = async (_id: string, name: string, color: string) => {
    try {
      if (!_id || !name || !color) {
        throw new Error(t('page.userSettings.labels.error.color-name'));
      }
      if (name.length < 1 || name.length > 200) {
        throw new Error(t('page.userSettings.labels.error.length'));
      }

      const { success } = await updateLabel({ id: _id, name, color });
      if (!success) {
        throw new Error(t('page.userSettings.labels.error.update'));
      }
      setAllLabels((prevLabels) =>
        prevLabels.map((label) =>
          label?._id === _id ? { ...label, name, color } : label,
        ),
      );
      setLabels((prevLabels) =>
        prevLabels.map((label) =>
          label._id?.toString() === _id ? { ...label, name, color } : label,
        ),
      );
    } catch (e) {
      captureExceptionWithMessage('labels handleUpdate', e);
    }
  };

  const handleInsert = async (labelName: string, labelColor: string) => {
    try {
      if (!labelName || !labelColor) {
        throw new Error(t('page.userSettings.labels.error.color-name'));
      }
      const { success, data } = await createLabel({
        name: labelName,
        color: labelColor,
        type: 'asset',
      });

      if (!data?.[0]) {
        throw new Error('no data found');
      }

      const [{ _id, name, color, dateCreated }] = data;
      const newLabel = {
        _id,
        name,
        color,
        includedInCount: 0,
        dateCreated: dateCreated ? new Date(dateCreated) : new Date(),
        isEditing: false,
        source: 'manifest' as 'manifest',
      };
      setLabels((prevLabels) => {
        const labels = prevLabels.filter((label) => label?.new !== true);
        return [newLabel, ...labels];
      });
      setAllLabels((prevLabels) => [newLabel, ...prevLabels]);
    } catch (e) {
      captureExceptionWithMessage('createLabel', e);
    }
    setNewLabelButtonDisabled(false);
  };

  const checkIfLabelExists = (labelName?: string): boolean => {
    return Boolean(allLabels.find((label) => label.name === labelName));
  };

  const handleDelete = async (_id: string | undefined) => {
    try {
      if (_id) {
        const { success } = await deleteLabel({ id: _id });
        if (!success) {
          throw new Error(t('page.userSettings.labels.error.deletion'));
        }
        setAllLabels((prevAllLabels) =>
          prevAllLabels.filter((label) => label._id !== _id),
        );
      } else {
        setNewLabelButtonDisabled(false);
      }
      setLabels((prevLabels) =>
        prevLabels.filter((label) =>
          _id ? label._id?.toString() !== _id : label._id !== undefined,
        ),
      );
    } catch (e) {
      captureExceptionWithMessage('labels handleDelete', e);
    }
  };

  // Separate labels into two sections based on the source field.
  const manifestLabels = labels.filter(
    (label) => label.source === 'manifest' || !label.source,
  );
  const githubLabels = labels.filter((label) => label.source === 'github');

  return (
    <div className="page-labels">
      <div className="header">{t('page.userSettings.labels.header')}</div>
      <div className="sub-header">{t('page.userSettings.labels.subHeader')}</div>
      <div className="labels-actions">
        <BasicSearch
          handleSearch={handleSearch}
          minCharCount={1}
          placeholder={t('page.userSettings.labels.filterByName')}
        />
        {hasWriteAccess && (
          <Button onClick={startCreateNewLabel} disabled={newLabelButtonDisabled}>
            {t('page.userSettings.labels.newLabel')}
          </Button>
        )}
      </div>
      <div className={styles.labelSection}>
        <div className="labels">
          {manifestLabels.map((label: InterfaceManageableLabel) => (
            <Label
              key={label._id?.toString() || uuidv4()}
              label={label}
              updateFn={handleUpdate}
              insertFn={handleInsert}
              deleteFn={handleDelete}
              checkExistFn={checkIfLabelExists}
              hasWriteAccess={hasWriteAccess}
            />
          ))}
        </div>
      </div>
      {importGhLabelsFeatureFlag ? (
        <div className="labels-section">
          <div className={styles.sectionTitle}>
            {t('page.userSettings.labels.importedFromGithub', {
              labelCount: githubLabels.length,
            })}
          </div>
          <div className="labels">
            {githubLabels.map((label: InterfaceManageableLabel) => (
              <Label
                key={label._id?.toString() || uuidv4()}
                label={label}
                updateFn={handleUpdate}
                insertFn={handleInsert}
                deleteFn={handleDelete}
                checkExistFn={checkIfLabelExists}
                hasWriteAccess={hasWriteAccess}
                isGithubLabel
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Labels;
