import React, { useState } from 'react';
import { Card, Select, Switch, Text, Title, Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../../components/Loading';
import { useOrganizationId } from '../../../../../hooks/utils/useOrganizationId';
import { usePostGitHubDefaultSettings } from '../../../../../hooks/mutations/usePostGitHubDefaultSettings';
import { InterfaceFullGithubIntegration } from '../../../../../types/FullInterfaces';
import styles from './GitHubOrganizationSettings.module.scss';
import { TriggerType } from '@manifest-cyber/types/interface/dbIntegration';
import { useOrganizationFeatureFlag } from '../../../../../hooks/useOrganizationFeatureFlag';
import { useDisclosure } from '@mantine/hooks';
import { DisableGithubLabelsConfirmationModal } from './DisableGithubLabelsConfirmationModal';

interface Props {
  fetchedGitHubIntegration: InterfaceFullGithubIntegration;
  isLoadingGitHubIntegration: boolean;
}

export const GitHubOrganizationSettings = ({
  fetchedGitHubIntegration,
  isLoadingGitHubIntegration,
}: Props) => {
  const { t } = useTranslation();
  const [currentOrgId] = useOrganizationId();
  const { mutateAsync: postGitHubDefaultSettings } = usePostGitHubDefaultSettings();
  const { isEnabled: importGhLabelsFeatureFlag } =
    useOrganizationFeatureFlag('import-gh-labels');
  const [opened, { open, close }] = useDisclosure(false);

  const githubIntegration = fetchedGitHubIntegration.githubIntegration[0];

  const [sbomFrequency, setSbomFrequency] = useState<TriggerType>(
    (githubIntegration?.defaultRepositoryOptions?.triggerType as TriggerType) || 'push',
  );

  const [importLabels, setImportLabels] = useState<boolean>(
    githubIntegration?.defaultRepositoryOptions?.enableTopicsImport ?? false,
  );

  const handleFrequencyChange = async (value: TriggerType) => {
    setSbomFrequency(value);
    await postGitHubDefaultSettings({
      manifestOrganizationId: (
        githubIntegration?.manifestOrganizationId || currentOrgId
      )?.toString()!,
      githubOrganizationId: githubIntegration.githubOrganizationId?.toString()!,
      githubIntegrationId: githubIntegration._id?.toString()!,
      defaultRepositoryOptions: {
        triggerType: value,
        enableTopicsImport: importLabels,
      },
    });
  };

  const handleImportLabelsChange = async (checked: boolean) => {
    setImportLabels(checked);
    await postGitHubDefaultSettings({
      manifestOrganizationId: (
        githubIntegration?.manifestOrganizationId || currentOrgId
      )?.toString()!,
      githubOrganizationId: githubIntegration.githubOrganizationId?.toString()!,
      githubIntegrationId: githubIntegration._id?.toString()!,
      defaultRepositoryOptions: {
        triggerType: sbomFrequency,
        enableTopicsImport: checked,
      },
    });
  };

  const confirmDisableLabels = async () => {
    close();
    await handleImportLabelsChange(false);
  };

  const cancelDisableLabels = () => {
    close();
  };

  if (isLoadingGitHubIntegration) {
    return <Loading />;
  }

  return (
    <div className="github-integration-edit-settings anim-slideInUpShort">
      <Title order={5} mt={32}>
        {t('page.userSettings.integrations.github.labels.sbomGeneration')}
      </Title>
      <Card withBorder shadow="sm" mb="md" mt={12} className={styles.card}>
        <Flex justify="space-between">
          <Flex direction="column">
            <Title order={5} className={styles.cardTitle}>
              {t(
                'page.userSettings.integrations.github.default-sbom-generation-frequency',
              )}
            </Title>
            <Text size="sm" mt={4} className={styles.cardDescription}>
              {t(
                'page.userSettings.integrations.github.labels.sbomGenerationDescription',
              )}
            </Text>
          </Flex>

          <Select
            withinPortal={true}
            mt="sm"
            disabled={githubIntegration.status === 'suspended'}
            value={sbomFrequency}
            onChange={(value) => {
              if (value) handleFrequencyChange(value as TriggerType);
            }}
            data={[
              {
                value: 'push',
                label: t('page.userSettings.integrations.github.commit-push'),
              },
              {
                value: 'time',
                label: t('page.userSettings.integrations.github.time-based'),
              },
            ]}
          />
        </Flex>
      </Card>
      {importGhLabelsFeatureFlag ? (
        <>
          <Title order={5} weight={700} mt={32}>
            {t('page.userSettings.integrations.github.labels.title')}
          </Title>
          <Card withBorder shadow="sm" className={styles.card} mt={12}>
            <Flex justify="space-between" gap={24}>
              <Flex direction="column">
                <Title order={5} className={styles.cardTitle}>
                  {t('page.userSettings.integrations.github.labels.importGithubLabels')}
                </Title>
                <Text size="sm" mt={4} className={styles.cardDescription}>
                  {t('page.userSettings.integrations.github.labels.description')}
                </Text>
              </Flex>
              <Switch
                checked={importLabels}
                onChange={(event) => {
                  const checked = event.currentTarget.checked;
                  if (checked) {
                    void handleImportLabelsChange(true);
                  } else {
                    open();
                  }
                }}
              />
            </Flex>
          </Card>
        </>
      ) : null}
      <DisableGithubLabelsConfirmationModal
        opened={opened}
        onConfirm={confirmDisableLabels}
        onCancel={cancelDisableLabels}
      />
    </div>
  );
};
